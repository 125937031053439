import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import Button from '../../atoms/Button/Button'
import { ArrowRight } from '../../atoms/NewIcons'
import Link from 'next/link'
import Trans from '../../atoms/Trans/Trans'
import NewProdottoCard from '../../molecules/NewProdottoCard/NewProdottoCard'
import { GETPRODOTTOVALUES, GETPRODOTTOVALUESPRODOTTI } from '../../../utils/endpoint'
import { api } from '../../../utils'
import { useRouter } from 'next/router'

const LasciatiIspirare = ({ categorie, prezzoLoaded, utente }) => {
  // metto l'indice in currentActive
  const [currentActive, setCurrentActive] = useState(categorie.length > 0 ? 0 : null)
  const [loaded, setLoaded] = useState(false)
  const [finalUrl, setFinalUrl] = useState(categorie[0]?.url)
  const [prod, setProd] = useState(categorie)
  const router = useRouter()

  useEffect(async () => {
    if (!categorie[currentActive ? currentActive : 0].prodotti_categoria[0]) {
      return
    }
    let alreadyLoaded = categorie[currentActive ? currentActive : 0].prodotti_categoria[0]
      .articoli_colore.listino
      ? true
      : false

    if (alreadyLoaded) {
      setLoaded(true)
      return
    }

    let ids = []
    categorie[currentActive ? currentActive : 0].prodotti_categoria
      .slice(0, 3)
      .map((item) => ids.push(item.id))

    const params = {
      lang: router.locale,
      prodotti_ids: ids,
      listing: true,
    }
    await api
      .get(GETPRODOTTOVALUESPRODOTTI, { params })
      .then((response) => {
        categorie[currentActive ? currentActive : 0].prodotti_categoria.map(
          (elementi) =>
            (elementi.listino = response.data.find(
              (prezzoArticolo) => prezzoArticolo.prodotto__id == elementi.id
            ))
          // elementi.articoli_colore.map(
          //   (articolo) =>
          //     (articolo.listino = response.data.find(
          //       (prezzoArticolo) => prezzoArticolo.articolo__id == articolo.id
          //     ))
          // )
        )
      })
      .catch((error) => console.log(error))
    setProd(categorie)
    setLoaded(true)
  }, [currentActive])

  const categories = prod.map((obj, k) => {
    return (
      <div
        key={'categorie-' + k}
        className={
          'inspire__card__container__leftSide__categoria' +
          (currentActive === k ? ' inspire__card__container__leftSide__categoria--active' : '')
        }
        onClick={() => {
          setCurrentActive(k), setLoaded(false)
          setFinalUrl(obj?.url)
        }}
      >
        {obj.nome_categoria}
      </div>
    )
  })
  const products =
    currentActive === null
      ? []
      : prod[currentActive].prodotti_categoria.slice(0, 3).map((prodotto, k) => (
          <div
            className="inspire__card__item"
            key={'prodotti-' + currentActive.toString() + '-' + k.toString()}
          >
            <NewProdottoCard utente={utente} prodotto={prodotto} priceLoaded={loaded} index={k} />
          </div>
        ))

  return (
    <div className="inspire__card">
      <div className="w-container">
        <div className="inspire__card__container">
          <div className="inspire__card__container__leftSide">
            <p className="inspire__card__container__leftSide__title">
              <Trans chiave="Lasciati ispirare dai nostri articoli" />
            </p>
            <div className="inspire__card__container__leftSide__categorie inspire__card__container__leftSide__categorie--desktop">
              {categories}
            </div>
            <div className="inspire__card__container__leftSide__categorie inspire__card__container__leftSide__categorie--mobile">
              <Swiper slidesPerView="auto">
                {prod.map((obj, k) => (
                  <SwiperSlide key={'categorie-mobile-' + k}>
                    <div
                      className={
                        'inspire__card__container__leftSide__categoria' +
                        (currentActive === k
                          ? ' inspire__card__container__leftSide__categoria--active'
                          : '')
                      }
                      onClick={() => {
                        setCurrentActive(k), setLoaded(false)
                        setFinalUrl(obj?.url)
                      }}
                    >
                      {obj.nome_categoria}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
          <div className="inspire__card__container__rightSide">{products}</div>
        </div>
        <div className="inspire__card__button">
          <Link href={finalUrl ? finalUrl : '#'}>
            <a>
              <Button label="Vedi tutti" icon={<ArrowRight />} size="large" variant="ghost" />
            </a>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default LasciatiIspirare
