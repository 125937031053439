import React from 'react'
import Link from 'next/link'
import MyImage from '../../atoms/MyImage/MyImage'

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

const Carousel = ({ slides }) => {
  return (
    <div className="carousel swiper--main">
      <Swiper autoplay={{ delay: 5000 }} navigation={true} pagination={true} slidesPerView={1}>
        {slides.map((slide, k) => (
          <SwiperSlide key={k}>
            {slide.url ? (
              <Link href={slide.url}>
                <a className="carousel__item">
                  <MyImage
                    src={slide.image}
                    alt={''}
                    title={''}
                    width={1920}
                    height={456}
                    priority={k === 0 ? true : false}
                    loading={k === 0 ? 'eager' : 'lazy'}
                  />
                </a>
              </Link>
            ) : (
              <div className="carousel__item">
                <MyImage
                  src={slide.image}
                  alt={''}
                  title={''} // passiamo il titolo
                  width={1920}
                  height={456}
                  priority={k === 0 ? true : false}
                  loading={k === 0 ? 'eager' : 'lazy'}
                />
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default Carousel
