import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import { ArrowRight } from '../../atoms/NewIcons'
import Button from '../../atoms/Button/Button'

const NewsCard = ({ news }) => {
  return (
    <div className="news">
      <div className="news__imgContainer">
        <Link href={news.url}>
          <a href={news.url}>
            <img src={news.preview_image_url} className="news__image" />
          </a>
        </Link>
        {news.categoria ? (
          <span className="news__imgContainer__label">{news.categoria.titolo}</span>
        ) : null}
      </div>
      <div className="news__content">
        <p className="news__content__data">{news.data}</p>
        <Link href={news.url}>
          <a href={news.url} className="news__content__titolo">
            {news.titolo}
          </a>
        </Link>
        <Link href={news.url}>
          <a href={news.url} className="news__content__link">
            <Button label="Leggi Articolo" size="medium" variant="ghost" />
          </a>
        </Link>
      </div>
    </div>
  )
}

NewsCard.propTypes = {
  news: PropTypes.object,
}

export default NewsCard
