import Error from 'next/error'
import { MetaSeo } from '../components/index'
import {
  PAGINEDETAIL,
  NEWS,
  HOMEPAGE_DATA,
  HOMEPAGE_DATA_MENU,
  GETPRODOTTOVALUESPRODOTTI,
} from '../utils/endpoint'
import { api } from '../utils'
import HomepageTemplate from '../components/template/HomepageTemplate/HomepageTemplate'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

const Home = ({ page, statusCode, t, homepageData, newsData }) => {
  if (!page) return <Error statusCode={statusCode} />
  const { service_gift } = useSelector((state) => state.servicegift)
  const [prezzoLoaded, setPrezzoLoaded] = useState(false)
  const [data, setData] = useState(homepageData)
  const [menuData, setMenuData] = useState({})
  const router = useRouter()

  useEffect(async () => {
    let ids = []
    homepageData.best_seller.forEach((itemBestSeller) => {
      itemBestSeller.prodotti_categoria.map((item) => !ids.includes(item.id) && ids.push(item.id))
    })
    if (!ids?.length > 0) {
      return
    }
    const params = {
      lang: router.locale,
      prodotti_ids: ids,
      listing: 1,
    }
    await api
      .get(GETPRODOTTOVALUESPRODOTTI, { params })
      .then((res) => setData(setDataResponse(res.data, homepageData)))
      .catch((error) => console.log(error))
    setPrezzoLoaded(true)
  }, [])

  useEffect(async () => {
    if (!service_gift) {
      const params = {
        lang: router.locale,
      }
      await api
        .get(HOMEPAGE_DATA_MENU, { params })
        .then((res) => setMenuData(res.data))
        .catch((error) => console.log(error))
    }
  }, [])

  const setDataResponse = (res, data) => {
    data.best_seller.forEach((itemBestSeller) => {
      itemBestSeller.prodotti_categoria.forEach(
        (item) =>
          (item.listino = res.find((prezzoArticolo) => prezzoArticolo.prodotto__id == item.id))
      )
    })
    return data
  }

  return (
    <>
      <MetaSeo seo={page.seo} />
      <main className="home-page">
        <div></div>
        {data && (
          <HomepageTemplate
            homepageData={data}
            newsData={newsData}
            homepageMenuData={menuData}
            prezzoLoaded={prezzoLoaded}
          />
        )}
      </main>
    </>
  )
}

export async function getServerSideProps({ params, locale, res }) {
  res.setHeader('Cache-Control', 'public, max-age=43200, must-revalidate')
  const pageResponse = await api.get(PAGINEDETAIL, { params: { chiave: 'homepage', lang: locale } })
  const homepageDataResponse = await api.get(HOMEPAGE_DATA, { params: { lang: locale } })
  const newsDataResponse = await api.get(NEWS, { params: { lang: locale, in_homepage: true } })
  const page = await pageResponse.data
  const homepageData = await homepageDataResponse.data
  const newsData = await newsDataResponse.data

  const toolbarProps = { toolbarPage: page }

  return {
    props: { page, ...toolbarProps, homepageData, newsData },
  }
}

export default Home
