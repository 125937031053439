import React from 'react'
import Button from '../../atoms/Button/Button'
import { ArrowRight } from '../../atoms/NewIcons'
import Link from 'next/link'
import MyImage from '../../atoms/MyImage/MyImage'

const CatalogoBanner = ({ img, title, description, url, index, cta }) => {
  return (
    <div className={`catalogo__banner catalogo__banner--color-${(index % 2) + 1}`}>
      <div className="catalogo__banner__content">
        <strong>{title}</strong>
        <p>{description}</p>
        <div className="catalogo__banner__content__button" style={{ maxWidth: 280 }}>
          <Link href={url ? url : ''}>
            <Button label={cta} variant="secondary" colorVariant="black" icon={<ArrowRight />} />
          </Link>
        </div>
        <MyImage
          className="catalogo__banner__content__img"
          src={img}
          alt={title}
          title={title}
          width={460}
          height={275}
        />
      </div>
    </div>
  )
}

export default CatalogoBanner
