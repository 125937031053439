import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { Button } from '../../atoms'
import { ArrowRight } from '../../atoms/NewIcons'
import useWindowDimensions from '../../../hooks/useWindowDimensions'

const CategoriaCardHome = ({ main_image, titolo, descrizione, slug, backgroundColor, index }) => {
  const { width } = useWindowDimensions()
  const isMobile = width < 600

  return isMobile ? (
    <div>
      <Link href={slug ? slug : ''}>
        <a className={`categoria-card-home categoria-card-home__mobile`}>
          {main_image && (
            <Image
              className="categoria-card-home__image"
              src={main_image}
              alt={titolo}
              title={titolo}
              layout={'responsive'}
              width={331}
              height={160}
              objectFit={'cover'}
            />
          )}
        </a>
      </Link>
      <div className="categoria-card-home__content">
        {titolo && <p className="categoria-card-home__title">{titolo}</p>}
        {descrizione && (
          <p className="categoria-card-home__description categoria-card-home__description__mobile">
            {descrizione}
          </p>
        )}
        <Link href={slug ? slug : ''}>
          <a>
            <Button label="Scopri di più" icon={<ArrowRight />} size="large" variant="ghost" />
          </a>
        </Link>
      </div>
    </div>
  ) : (
    <Link href={slug ? slug : ''}>
      <a className={`categoria-card-home`}>
        {main_image && (
          <Image
            className="categoria-card-home__image"
            src={main_image}
            alt={titolo}
            title={titolo}
            layout={'fill'}
            objectFit={'cover'}
          />
        )}
        <div className="categoria-card-home__content">
          {titolo && <p className="categoria-card-home__title">{titolo}</p>}
          {descrizione && <p className="categoria-card-home__description">{descrizione}</p>}
        </div>
        <Button
          className="categoria-card-home__cta"
          size="large"
          responsive={true}
          variant="secondary"
          colorVariant="black"
          icon={<ArrowRight />}
        />
      </a>
    </Link>
  )
}

export default CategoriaCardHome
