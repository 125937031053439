import React from 'react'
import Button from '../../atoms/Button/Button'
import { ArrowRight } from '../../atoms/NewIcons'
import Link from 'next/link'
import MyImage from '../../atoms/MyImage/MyImage'

const CatalogoBrandHome = ({ img, brand_image, title, url }) => {
  return (
    <Link href={url ? url : ''}>
      <a className="catalogo-brand-home">
        <div className="catalogo-brand-home__container">
          {img && (
            <MyImage
              className="catalogo-brand-home__image"
              src={img}
              alt={title}
              title={title}
              layout={'fill'}
              objectFit={'cover'}
              priority={false}
              loading="lazy"
            />
          )}
        </div>
        <div className="catalogo-brand-home__content">
          {img && (
            <MyImage
              className="catalogo-brand-home__image"
              src={brand_image}
              alt={title}
              title={title}
              layout="intrinsic"
              width={140}
              height={48}
              objectFit="contain"
              priority={false}
              loading="lazy"
            />
          )}
          <Button
            className="catalogo-brand-home__cta"
            size="large"
            responsive={true}
            variant="secondary"
            colorVariant="blue"
            icon={<ArrowRight />}
          />
        </div>
      </a>
    </Link>
  )
}

export default CatalogoBrandHome
