import React from 'react'
import Button from '../../atoms/Button/Button'
import { ArrowRight } from '../../atoms/NewIcons'
import Link from 'next/link'
import MyImage from '../../atoms/MyImage/MyImage'
import useTrans from '../../../hooks/useTrans'

const HomepageServiceCard = ({ servizi, url }) => {
  const t = useTrans()
  return (
    <div className="homepage-service">
      <div className="homepage-service__card-container">
        {servizi.map((obj, k) => {
          return (
            <div className="homepage-service__card" key={k}>
              <div className="homepage-service__card__icon">
                {obj.image && <MyImage src={obj.image} width={80} height={80} />}
              </div>
              <h2 className="homepage-service__card__title">{obj.name}</h2>
              <p className="homepage-service__card__description">{obj.description}</p>
            </div>
          )
        })}
      </div>
      <Link href={url ? url : ''}>
        <a>
          <Button
            label={t('Esplora i nostri servizi')}
            icon={<ArrowRight />}
            size="large"
            responsive={true}
            variant="ghost"
            className="homepage-service__cta"
          />
        </a>
      </Link>
    </div>
  )
}

export default HomepageServiceCard
